import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getSingleApp} from "../api/Api";
import {useSetRecoilState} from "recoil";
import {isLoading} from "../api/Atoms";
import '../style/Single.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'

import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import {Carousel, Button} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import LoadGif from "../style/load.gif";
import AdditionalDetails from './AdditionalDetails';
import TagManager from 'react-gtm-module'

const SingleApp = () => {
    const [googleData, setGoogleData] = useState('')
    const [appleData, setAppleData] = useState('')
    const setLoadingState = useSetRecoilState(isLoading)
    const navigate = useNavigate();
    const [isTopAd, setIsTopAd] = useState(0)
    const [language, setLanguage] = useState('')
    const [isVideoApp, setIsVideoApp] = useState(false)
    const [iframeSrc, setIframeSrc] = useState('https://d2lg0swrp15nsj.cloudfront.net/?wtbp=1')

    const [pdfReader, setPdfReader] = useState(false)

    const iframeRef1 = useRef(null);
    const iframeRef2 = useRef(null);

    const location = useLocation();
    let pathName = location.pathname.substring(1);
    let beaconUrl = 'https://stat.apps-meteor.com/rp'

    useEffect(() => {
        setLoadingState(true)
        getSingleApp(encodeURIComponent(pathName)).then((data) => {
            if (Array.isArray(data) && data.length === 0) {
                navigate('/404');
            } else {
                setGoogleData(data.google)
                setAppleData(data.apple)
                const sanitizedMarkdown = DOMPurify.sanitize(data.google.item.description);
            }
            setLoadingState(false)

        });

    }, [location]);

    useEffect(() => {
        const paramsToSave = ['gclid', 'campaign_id', 'adgroup_id', 'placement_id', 'creative_id', 'q', 'lan'];
        paramsToSave.forEach(param => {
            const value = getUrlParam(param);
            if (value) {
                saveToSessionStorage(param, value);
            }
        });
        if (window.location.href.includes('PDF%20Reader%20-%20PDF%20Viewer')) {
            setPdfReader(true);
        }
        sendBeacon('plp');
        const topAd = getUrlParam('nav');
        if (topAd === '1') {
            setIsTopAd(1);
        }
        if (topAd === '2') {
            setIsTopAd(2);
        }
        if (topAd === '3') {
            setIsTopAd(3);
        }
        if (window.location.href.includes('Video%20Editor%20%26%20Maker%20-%20InShot')) {
            setIsVideoApp(true);
        }
        if (window.location.href.includes('EveryPlate%3A%20Cooking%20Simplified')) {
            setIframeSrc('https://d2lg0swrp15nsj.cloudfront.net/?olpt=1');
        }
        if (window.location.href.includes('Simple%20Calendar')) {
            setIframeSrc('https://d2lg0swrp15nsj.cloudfront.net/?pruk=1');
        }
        if (window.location.href.includes('Print%20PDF%20Files%20-%20PDF%20Printer')) {
            setIframeSrc('https://d2lg0swrp15nsj.cloudfront.net/?wkol=1');
        }
        if (window.location.href.includes('Games%20Tips%26Tricks')) {
            setIframeSrc('https://d2lg0swrp15nsj.cloudfront.net/?wxvt=1');
        }



        const lang = getUrlParam('lan');
        if (lang) {
            setLanguage(lang);
        }
    }, []);


    const sendMessage = () => {
        const data = {
            g_campaign_id : getUrlParam('campaign_id') || getFromSessionStorage('campaign_id') || '',
            g_adgroup_id : getUrlParam('adgroup_id') || getFromSessionStorage('adgroup_id') || '',
            g_placement_id : getUrlParam('placement_id') || getFromSessionStorage('placement_id') || '',
            g_creative_id : getUrlParam('creative_id') || getFromSessionStorage('creative_id') || '',
            g_keyword : getUrlParam('q') || getFromSessionStorage('q') || '',
            lan: getUrlParam('lan') || getFromSessionStorage('lan') || navigator.language,
            gclid : getFromSessionStorage('gclid')
        }

        if (iframeRef1.current) {
            const message = {
                type: iframeSrc,
                url: data,
                id: 1
            };
            iframeRef1.current.contentWindow.postMessage(message, iframeSrc);
        }
        if (iframeRef2.current) {
            const message = {
                type: 'https://d2qqc8ssywi4j6.cloudfront.net/?wtbp=1',
                url: data,
                id: 2
            };
            iframeRef2.current.contentWindow.postMessage(message, 'https://d2qqc8ssywi4j6.cloudfront.net/?wtbp=1');
        }
    };


    const carouselRef = React.useRef();

    const goToPrevious = () => {
        carouselRef.current.prev();
    };

    const goToNext = () => {
        carouselRef.current.next();
    };

    const goTo = (path) => {
        navigate("/" + encodeURIComponent(path))
    }

    const formatNumber = (num) => {
        //convert string to number after removing all characters except numbers
        if (typeof num === 'string') {
            num = num.replace(/\D/g, '');
        }
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1) + 'B';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        if (num) {
            return num.toString();
        }
    }

    const getUrlParam = (name) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    const getAllUrlParams = () => {
        var urlObj = new URL(window.location.href);
        var searchParams = new URLSearchParams(urlObj.search);
        var paramsObj = {};
        searchParams.forEach(function (value, key) {
            paramsObj[key] = value;
        });

        return paramsObj;
    }

    const goToAdvertisement = () => {
        sendBeacon('plcp');
        const campaign_id = getUrlParam('campaign_id') || getFromSessionStorage('campaign_id');
        const adgroup_id = getUrlParam('adgroup_id') || getFromSessionStorage('adgroup_id');
        const placement_id = getUrlParam('placement_id') || getFromSessionStorage('placement_id');
        const creative_id = getUrlParam('creative_id') || getFromSessionStorage('creative_id');
        const q = getUrlParam('q') || getFromSessionStorage('q');
        // let url = 'https://pdf-harmony.com/harmonyDownload.html?campaign_id=' + campaign_id + '&adgroup_id=' + adgroup_id + '&placement_id=' + placement_id + '&creative_id=' + creative_id + '&q=' + q;
        let url = 'https://www.totalav.com/product/antivirus-pro';
        // if (isVideoApp) {
        //     url = 'https://videownload.com/download.html?campaign_id=' + campaign_id + '&adgroup_id=' + adgroup_id + '&placement_id=' + placement_id + '&creative_id=' + creative_id + '&q=' + q;
        // }
        const gclidStorage = getFromSessionStorage('gclid');
        if (gclidStorage) {
            url += '&gclid=' + gclidStorage;
        }
        window.open(url, '_blank')
    }

    const goToAdvertisementIframe = () => {
        sendBeacon('plcp');
    }

    const sendBeacon = (type) => {
        const data = getAllUrlParams();
        data['type'] = type;
        if (!data['lan']) {
            data['lan'] = 'en';
        }
        navigator.sendBeacon(beaconUrl, btoa(JSON.stringify(data)));
    }

    const saveToSessionStorage = (key, value) => {
        sessionStorage.setItem(key, value);
    };

    const getFromSessionStorage = (key) => {
        return sessionStorage.getItem(key);
    };

    const adClass = isTopAd === 3 ? "Single-App-Container-New" : "Single-App-Container";

    return (
        <div>
            {isTopAd === 1 ?
                <div className="Advertisement-Nav">
                    <div className="Advertisement-Container-Nav" onClick={goToAdvertisement}>
                        <div className="Advertisement-Text">
                            Advertisement
                        </div>
                        <div className="btn-push">
                            <span className="elementor-button-text">DOWNLOAD</span>
                        </div>
                    </div>
                </div>
                : null}
            {googleData === '' ? null : (

                <div className={adClass} style={{marginTop: isTopAd !== 1 ? "5vh" : null}}>
                    <div className="Single-App-Details">
                        <div className="Single-App-Header">
                            <div className="Details-Container">
                                <div className="Details">
                                    <img src={googleData.item.icon} alt={googleData.item.title}/>
                                    <div>
                                        <h1>{googleData.item.title}</h1>
                                        <a target="_blank"
                                           href={googleData.item.developer_url}>{googleData.item.developer}</a>
                                    </div>
                                </div>
                                <div className='Rating-Container'>
                                    {googleData.item.rating.value ? <div>
                                   <span>
                                        {googleData.item.rating.value}&nbsp;
                                       <FontAwesomeIcon icon={faStar}/> </span>

                                        <span>{formatNumber(googleData.item.rating.votes_count)} ratings</span>
                                    </div> : null}
                                    <div>
                                        <span>{formatNumber(googleData.item.installs)}</span>
                                        <span>Downloads</span>
                                    </div>
                                </div>
                            </div>

                            {isTopAd !== 2 ?
                                <div className="Advertisement-Top">
                                    <div className="Advertisement-Container" onClick={goToAdvertisement}>
                                        <div className="Advertisement-Text">
                                            Advertisement
                                        </div>
                                        <div className="btn-push">
                                            <span className="elementor-button-text">DOWNLOAD</span>
                                        </div>
                                    </div>
                                </div> :
                                <iframe className="Advertisement-Top"
                                        onClick={goToAdvertisementIframe}
                                        scrolling="no"
                                        frameBorder='0'
                                        ref={iframeRef1}
                                        onLoad={sendMessage}
                                        src={iframeSrc}></iframe>
                            }
                        </div>
                        <AdditionalDetails googleData={googleData}/>
                        {isTopAd !== 2 ?
                            <div className="Advertisement-Bottom">
                                <div className="Advertisement-Container" onClick={goToAdvertisement}>
                                    <div className="Advertisement-Text">
                                        Advertisement
                                    </div>
                                    <div className="btn-push">
                                        <span className="elementor-button-text">DOWNLOAD</span>
                                    </div>
                                </div>
                            </div> :
                            <iframe className="Advertisement-Bottom"
                                    scrolling="no"
                                    frameBorder='0'
                                    ref={iframeRef2}
                                    onLoad={sendMessage}
                                    onClick={goToAdvertisementIframe}
                                    src='https://d2qqc8ssywi4j6.cloudfront.net/?wtbp=1'>
                            </iframe>
                        }
                        <div className='Single-App-Description'>
                            <ReactMarkdown children={googleData.item.description}></ReactMarkdown>
                        </div>
                        <div>
                            <div className="carousel-container">
                                <Carousel ref={carouselRef}>
                                    {googleData.item.images.map((result, index) => (
                                        <div key={index}>
                                            <img src={result} alt={googleData.item.title}/>
                                        </div>
                                    ))}
                                </Carousel>
                                <Button className="carousel-control left"
                                        onClick={goToPrevious}><LeftOutlined/></Button>
                                <Button className="carousel-control right" onClick={goToNext}><RightOutlined/></Button>
                            </div>
                        </div>
                        <div className="Buttons-Container">
                            <a className="green" href={pdfReader? "https://play.google.com/store/apps/details?id=com.tsoft.pdfreader&hl=en&gl=us" : googleData.item.url} target="_blank"><img
                                src="/assets/googleplay.png"/>Get It From Google Play </a>
                            {appleData ? <a className="blue" href={pdfReader ? "https://apps.apple.com/us/app/pdf-reader-all-pdf-viewer/id6448699108" : appleData.item.url} target="_blank"><img
                                src="/assets/appstore.png"/>Get It From App Store</a> : null}
                        </div>
                    </div>
                    {googleData.item.similar_apps ? <div className="Similar-Apps-Container">
                        <h2>Similar Apps & Games</h2>
                        <ul style={{
                            display: isTopAd === 3 ? "flex" : null,
                            flexWrap: isTopAd === 3 ? 'wrap' : null,
                            justifyContent: isTopAd === 3 ? 'center' : null,
                            padding: isTopAd === 3 ? '0' : null
                        }}>
                            {googleData.item.similar_apps.map((result, index) => (
                                <li key={index}>
                                    <div key={index} className='Similar-App-Block' onClick={() => goTo(result.title)} style={{flexDirection: isTopAd === 3 ? "column" : "row"}}>
                                        <span>{index + 1}</span>
                                        <img
                                            src={result.icon}
                                            alt={result.title}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = LoadGif;
                                            }}
                                            style={{width: isTopAd === 3 ? "100px" : null}}
                                        />
                                        <h3>{result.title}</h3>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div> : null}
                </div>
            )
            }

        </div>
    );
};

export default SingleApp;
